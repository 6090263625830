//import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage";
import "bootstrap";
import toastr from "toastr";

toastr.options = {
  closeButton: true,
};
window.toastr = toastr;

import $ from "jquery";
window.jQuery = window.$ = $;

// Turbolinks.start()
ActiveStorage.start();

import Rails from "@rails/ujs";
// memo: 本番のみ二重起動するため、条件分岐を追加
if (!window.Rails || !Rails.start) {
  Rails.start();
}

import "./commons_common";
import "./search";
